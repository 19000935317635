import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  SeresSilkDuvetWarrantyHeroSection,
  SeresSilkDuvetWarrantyIntroSection,
  SeresSilkDuvetWarrantyContentSection,
} from '../../components/sections';

const SeresSilkDuvetWarranty: React.FC = () => {
  return (
    <Layout>
      <SEO title="The Seres Silk Duvet" />
      <SeresSilkDuvetWarrantyHeroSection />
      <SeresSilkDuvetWarrantyIntroSection />
      <SeresSilkDuvetWarrantyContentSection />
    </Layout>
  );
};

export default SeresSilkDuvetWarranty;
